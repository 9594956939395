export default {
  BattleCry: 'Накладывается на вашего героя - повернутый герой становится снова активным',
  MeleeCombat: 'АРТЕФАКТ. Вместо хода героя: прибавить воину +2 к атаке на единоразовую атаку в его ход',
  RangeCombat: 'Накладывается на Стрелка, этот воин получают +1 к атаке',
  Protection: 'Накладывается на воина, он получает +1 защиту от атак воинов, в том числе при ответном ударе',
  Resistance: 'Накладывается на воина, он получает 50% защиту от ударных заклинаний с округлением в большую сторону',
  Durability: 'Накладывается на воина, он получает +3 к здоровью',
  FirstAid: 'Излечивает у воина 4 здоровья',
  Leadership: 'Кладется в линию. Воины в этой линии получают +1 к атаке до вашего следующего хода',
  BattleTactics: 'Кладется рядом с вашей линией. Все воины в этой линии получают СТРЕМИТЕЛЬНОСТЬ, если перемещаются по этой линии',
  RighteousHammer: 'АРТЕФАКТ. Вместо хода героя: атаковать вражеского воина силой 2 + Х, где Х равен количеству воинов на поле у противника, минус количество воинов у вас на поле',
  RighteousHammerCast: 'Выберите воина для атаки',
  AddAttackCast: 'Выберите воина для увеличения атаки',
  PlateArmor: 'АРТЕФАКТ. Снижает урон от воинов по герою на 2 (расходует заряды при каждой атаке)',
  LeatherArmor: 'АРТЕФАКТ. Снижает урон от воинов по герою на 1 (расходует заряды при каждой атаке)',
  WindStaff: 'АРТЕФАКТ. Вместо хода героя: переместить любоого воина на поле на свободную клетку',
  GoldenBow: 'АРТЕФАКТ. Ваши стрелки получают МАССОВУЮ АТАКУ (каждая атака стрелка расходует заряд)',
  MovingCast: 'Выбрать воина для перемещения',
};
